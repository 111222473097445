<template>
  <v-container fill-height>
    <v-row>
      <v-col class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { processZoomCode } from '@/services/zoom'
import { createNamespacedHelpers } from 'vuex'
// import { processSignInSuccess } from '@/components/utils/user'
const { mapMutations: mapMutationsAuth, mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  data: () => ({}),
  computed: {
    ...mapStateAuth(['uid'])
  },
  methods: {
    ...mapMutationsAuth(['updateAccessToken']),
    isValidState(state) {
      return state === state // TODO:
    }
  },
  async mounted() {
    const query = this.$route.query
    const { error, code, state } = query
    if (error) {
      alert('サインインできませんでした。')
      this.$router.replace('/')
    } else {
      try {
        await processZoomCode({
          code,
          redirectUri: `${window.location.origin}${window.location.pathname}`,
          invitedEmail: this.$route.params.invitedEmail
        }).then((result) => {
          return result.data
        })

        const stateObj = JSON.parse(state)
        console.log('zoom:state', stateObj)
        console.log('zoom:user', this.uid)

        // await processSignInSuccess({
        //   user: { uid: this.uid },
        //   token: {
        //     accessToken: tokens.access_token,
        //     refreshToken: tokens.refresh_token,
        //     expiresIn: tokens.expires_in,
        //     scope: tokens.scope,
        //     tokenType: tokens.token_type,
        //     extExpiresIn: tokens.ext_expires_in
        //   },
        //   provider: 'zoom.us',
        //   invitedEmail: this.$route.params.invitedEmail
        // })
        this.$router.replace(stateObj.origin || '/calendar')
        // if (await this.isValidRefreshToken(tokens.refreshToken, this.uid)) {
        // } else {
        //   throw 'Meet Awesome の Zoom アカウントへのアクセス権を削除し、再度サインインしてください。'
        // }
      } catch (error) {
        alert('サインイン処理中にエラーが起きました。' + error)
        this.$router.replace('/')
      }
    }
  }
}
</script>
